import React, { FC, useEffect, useRef, useState } from 'react';
import { InstantSearch } from 'react-instantsearch-dom';
import { useLocation } from '@reach/router';
import { isEmpty } from 'lodash';
import qs from 'qs';

import { DEBOUNCE_TIME } from 'utils/constants';

import { useAlgoliaContext } from '../../contexts/algoliaContext';
import { InstantSearchContainerProps } from './models';

const createURL = (state) => `?${qs.stringify(state)}`;

const searchStateToUrl = (location, searchState) =>
  searchState ? `${location.pathname}${createURL(searchState)}` : '';

const urlToSearchState = (location) => qs.parse(location.search?.slice(1));

const InstantSearchContainer: FC<InstantSearchContainerProps> = ({
  indexName,
  cleared,
  children,
}) => {
  const searchClient = useAlgoliaContext();
  const location = useLocation();

  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const setStateId = useRef<any>();

  useEffect(() => {
    const nextSearchState = urlToSearchState(location);

    if (JSON.stringify(searchState) !== JSON.stringify(nextSearchState)) {
      setSearchState(nextSearchState);
    }
  }, [location]);

  useEffect(() => {
    if (cleared) {
      setSearchState({});
    }
  }, [cleared]);

  const onSearchStateChange = (nextSearchState) => {
    clearTimeout(setStateId.current);

    setStateId.current = setTimeout(() => {
      window.history.pushState(nextSearchState, '', searchStateToUrl(location, nextSearchState));
    }, DEBOUNCE_TIME);

    setSearchState(nextSearchState);
  };

  if (isEmpty(searchClient)) return null;

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indexName}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}
    >
      {children}
    </InstantSearch>
  );
};

export default InstantSearchContainer;
